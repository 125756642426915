import React, { useState, useEffect, createRef, forwardRef } from 'react';
import ReactDOM from 'react-dom';
import { graphql, navigate } from 'gatsby';
import update from 'immutability-helper';
import lerp from 'lerp';
import { useTheme } from '@mui/material/styles';
import {
  Typography,
  Grid,
  Hidden,
  Dialog,
  DialogContent,
  TextField,
  CircularProgress,
  Divider,
  Box,
} from '@mui/material';
import { Button } from 'gatsby-theme-material-ui';
import anime from 'animejs';
import { init, send } from 'emailjs-com';
import PageLayout from '../components/pageLayout';
import Footer from '../components/footer';
import {
  LogoSlide,
  LogoSubTextSlide,
  PostSlide,
  PostContentSlide,
  CoverImageSlide,
  CoverTextSlide,
} from '../components/slides';
import {
  SLIDE_SCALE,
  ANIM_ZOOM_DURATION,
  DIST_RATIO,
  LOGO_SCALE,
  BKG_COLOR,
  WINDOW_HEIGHT_DEFAULT,
  PARALLAX_DAMPING,
} from '../constants';

import {
  genHelmet,
  EasingFunctions,
  clamp,
  getWindowSize,
} from '../actions/baseActions';
import {
  createSplashScene,
  addDomToScene,
} from '../actions/sceneActions/sceneLoader';

import theme from '../gatsby-theme-material-ui-top-layout/theme';

// EmailJS
init('user_aL2vCdR2e8iSo4GFfsYsx');

const styles = {
  root: {
    // height: '100%'
  },
  viewport: {
    position: 'fixed',
    top: 0,
    left: 0,
    background: '#fff',
    width: '100vw',
    height: '100vh',
    pointerEvents: 'none',
  },
  scrollWrapper: {
    pointerEvents: 'none',
    background: 'transparent',
    position: 'absolute',
    width: '100%',
    left: 0,
    marginTop: -theme.spacing(12),
    '& > div': {
      height: '100%',
    },
  },
  dialogRoot: {
    minWidth: '300px',
    minHeight: '150px',
  },
  progress: {
    color: '#000',
  },
  arrow: {
    fontSize: '2rem',
    padding: `0 ${theme.spacing(1)}`,
  },
  vertDivider: {
    width: 3,
    background: '#000',
  },
  horizDivider: {
    width: '30vw',
    height: 2,
    background: '#000',
    margin: '0 35vw',
  },
};

const RootIndex = (props) => {
  const pageData = props.data.contentfulPage;

  const { posts } = pageData;

  const viewport = createRef();
  const rootElement = createRef();
  const logoRef = createRef();
  const logoSubOneRef = createRef();
  const callToActionRef = createRef();
  const callToActionButtonRef = createRef();
  const scrollRef = createRef();
  const postRefs = new Array(posts.length);
  const postRefsContent = new Array(posts.length);
  for (var i = 0; i < posts.length; i++) {
    postRefs[i] = createRef();
    postRefsContent[i] = createRef();
  }

  const [scene, setScene] = useState(null);
  const [camera, setCamera] = useState(null);
  const [origCamPos, setOrigCamPos] = useState(null);
  const [group, setGroup] = useState(null);
  const [render, setRender] = useState(() => {});
  const [slides, setSlides] = useState(new Array(posts.length + 1));

  const [windowHeight, setWindowHeight] = useState(WINDOW_HEIGHT_DEFAULT);
  const [sceneSlideOffset, setSceneSlideOffset] = useState(0);
  const [scrollingId, setScrollingId] = useState();
  const [animeScroll, setAnimeScroll] = useState();
  const [slideAim, setSlideAim] = useState(0);
  const [slideAimTarget, setSlideAimTarget] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [body, setBody] = useState('');
  const [name, setName] = useState('');
  const [emailSending, setEmailSending] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [scrollHeight, setScrollHeight] = useState(
    WINDOW_HEIGHT_DEFAULT * (posts.length + 1)
  ); // fix this later
  const [windowWidth, setWindowWidth] = useState(getWindowSize().width);

  // setup scene
  useEffect(() => {
    const { scene, destroy, render, group, camera, animate } =
      createSplashScene(viewport);
    setScene(scene);
    setCamera(camera);
    setGroup(group);
    setRender(render);
    setOrigCamPos(camera.position.clone());

    setSceneSlideOffset(
      Math.tan(camera.fov / 2) * camera.position.distanceTo(group.position) * 2
    );

    setupScene({ scene, group, camera, viewport });

    animate();

    return () => {
      destroy();
    };
  }, []);

  // setup onscroll functionality
  useEffect(() => {
    // console.log('scroll setup')
    if (typeof window !== `undefined`) window.onscroll = handleScroll;
    return () => {
      window.onscroll = null;
    };
  }, [scrollRef]);

  // handle resize updates
  useEffect(() => {
    updateSlidePositions(group, sceneSlideOffset);
  }, [sceneSlideOffset, group, windowHeight]);

  const updateSlidePositions = (group, sceneSlideOffset) => {
    if (!group) return;
    group.children.forEach((child, index) => {
      child.position.y = -1 * sceneSlideOffset * index;
    });
  };

  // handle phone loading
  useEffect(() => {
    handleSlideSizing(slides);
  }, [windowWidth, slides]);

  // const handleSlideSizing
  const handleSlideSizing = (slides) => {
    if (windowWidth < theme.breakpoints.values.sm) {
      // change slide text for small screens
      slides.forEach((slide, index) => {
        if (index === 0) {
          slide.children[0].position.y = -150;
          return;
        }
        slide.children[0].position.z =
          camera.position.z * DIST_RATIO * PARALLAX_DAMPING;
        slide.children[0].scale.fromArray([1.25, 1.25, 1.25]);
      });
    } else {
      // reset the positions
      slides.forEach((slide, index) => {
        if (index === 0) {
          slide.children[0].position.y = 0;
          return;
        }
        slide.children[0].position.z = camera.position.z * DIST_RATIO;
        slide.children[0].scale.fromArray([1, 1, 1]);
      });
    }
  };

  const setupScene = ({ scene, group, camera, viewport }) => {
    const tempSlides = [];

    // above the fold slide
    const dom = logoRef.current;
    const logoSlide = addDomToScene({
      element: dom,
      parent: group,
      camera,
      viewport,
    });
    logoSlide.position.z = camera.position.z * DIST_RATIO * 0.5;

    const subLogoSlideOne = addDomToScene({
      element: logoSubOneRef.current,
      parent: logoSlide,
      camera,
      viewport,
    });
    subLogoSlideOne.position.z = -camera.position.z * DIST_RATIO * 2;

    dom.style.opacity = 1;
    tempSlides.push(logoSlide);

    posts.forEach((post, index) => {
      // image
      const slide = addDomToScene({
        element: postRefs[index].current,
        parent: group,
        camera,
        viewport,
      });

      // text over image
      const contentSlide = addDomToScene({
        element: postRefsContent[index].current,
        parent: slide,
        camera,
        viewport,
      });
      contentSlide.position.z = camera.position.z * DIST_RATIO;

      // add to slides
      tempSlides.push(slide);
    });

    setSlides(tempSlides);
  };

  const handleScroll = (ev) => {
    // console.log(scrollRef)
    if (!scrollRef.current) return;

    const windowScroll = typeof window !== `undefined` ? window.scrollY : 0;
    const tempSlideAim =
      (windowScroll / (scrollHeight - windowHeight)) * (posts.length + 1);

    if (group) group.position.y = tempSlideAim * sceneSlideOffset;

    if (scrollRef.current.clientHeight !== scrollHeight) {
      // console.log('resetting div height')
      scrollRef.current.style.height = scrollHeight + 'px'; // fix this later
    }
  };

  return (
    <React.Fragment>
      <Box ref={rootElement} sx={styles.root}>
        {genHelmet(pageData, {
          type: 'page',
          // siteData: pageData,
          path: props.path,
        })}

        {/* -- SLIDES FOR 3D --- */}
        <LogoSlide ref={logoRef} style={{ opacity: 0 }} logo />
        <LogoSubTextSlide ref={logoSubOneRef} />
        {/* <CallToActionSlide ref={callToActionRef} />
        <CallToActionButtonSlide ref={callToActionButtonRef} /> */}

        {posts.map((post, index) => (
          <React.Fragment key={'post-' + index}>
            <PostSlide
              post={post}
              ref={postRefs[index]}
              imageStyle={{
                width: `100vw`,
              }}
            />
            <PostContentSlide
              post={post}
              ref={postRefsContent[index]}
              onClick={() => {
                const duration = ANIM_ZOOM_DURATION;

                // zoom in
                anime({
                  targets: camera.position,
                  z: 0,
                  easing: 'easeInQuad',
                  duration,
                });
                anime({
                  targets: [
                    postRefs[index].current,
                    postRefsContent[index].current,
                  ],
                  opacity: 0,
                  easing: 'easeInQuad',
                  duration,
                });

                // zoom back out for back button navigation
                // anime({
                //   targets: camera.position,
                //   z: origCamPos.z,
                //   easing: 'easeInQuad',
                //   duration: 100,
                //   delay: duration
                // })
                // anime({
                //   targets: [postRefs[index].current, postRefsContent[index].current],
                //   opacity: 1,
                //   easing: 'easeInQuad',
                //   duration: 100,
                //   delay: duration
                // })

                setTimeout(() => {
                  let prefix = '/projects/';
                  if (post.internal?.type === 'ContentfulPost')
                    prefix = /posts/;
                  navigate(`${prefix}${post.slug}`);
                }, duration * 0.9);
              }}
            />
          </React.Fragment>
        ))}

        {/* --- LAYOUT --- */}
        <PageLayout location={props.location} hideFooter={true}>
          {/* --- VIEWPORT --- */}
          <Box ref={viewport} sx={styles.viewport} />

          {/* --- SCROLL AREA --- */}
          <Box
            ref={scrollRef}
            sx={{ ...styles.scrollWrapper, height: scrollHeight }}
          >
            <Grid container direction="column" justifyContent="flex-end">
              <Footer
                sx={styles.footerOverride}
                pageData={pageData}
                includeCta
                threeJsPage
              />
            </Grid>
          </Box>
        </PageLayout>
      </Box>
    </React.Fragment>
  );
};

export default RootIndex;

// defining all fragments here

export const pageQuery = graphql`
  {
    contentfulPage(slug: { eq: "/" }) {
      slug
      headline
      title
      callToActionHeadline
      callToActionText {
        callToActionText
      }
      callToActionButtonText
      coverImage {
        title
        description
        gatsbyImageData
        banner: gatsbyImageData(width: 1200, formats: JPG, sizes: "1200px")
        thumbnail: gatsbyImageData(width: 600, formats: JPG, sizes: "600px")
      }
      posts {
        ... on ContentfulProject {
          headline
          slug
          title
          category
          internal {
            type
          }
          coverImage {
            title
            description
            gatsbyImageData
          }
        }
        ... on ContentfulPost {
          headline
          slug
          title
          textInImage
          internal {
            type
          }
          coverImage {
            title
            description
            gatsbyImageData
          }
        }
      }
    }
  }
`;
