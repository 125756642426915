import { getWindowSize } from '../actions/baseActions'

// 3D Slide Constants
export const SLIDE_SCALE = 0.75 // based on where the camera is in reference to images, etc
export const ANIM_ZOOM_DURATION = 1000 // ms
export const DIST_RATIO = 0.45 // for title over images, etc
export const LOGO_SCALE = 0.75 // for the above the fold logo
export const WINDOW_HEIGHT_DEFAULT = getWindowSize().height
export const PARALLAX_DAMPING = 0.25

export const BKG_COLOR = '#fff5f0'
